<template>
  <login-layout>
    <div class="panel">
      <h2>Wachtwoord resetten</h2>
      <p>Vul je nieuwe wachtwoord twee maal in:</p>
    </div>
    <div>
      <validation-observer ref="resetPasswordForm" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(onSubmit)">
          <div v-if="alertMessage" :class="`alert ${alertType}`">{{ alertMessage }}</div>

          <div class="form-group">
            <ValidationProvider v-slot="v" name="Wachtwoord" rules="required|min:8|confirmed:confirmation">
              <b-form-input v-model="password" type="password" name="password" class="form-control" placeholder="Wachtwoord" :state="getValidationState(v)" />
              <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider v-slot="v" name="Wachtwoord bevestiging" vid="confirmation">
              <b-form-input v-model="password_confirmation" type="password" name="password_confirmation" class="form-control" placeholder="Wachtwoord bevestiging" :state="getValidationState(v)" />
              <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <button type="submit" class="btn btn-success" :disabled="submitted">Wijzig wachtwoord</button>
        </b-form>
      </validation-observer>
    </div>
  </login-layout>
</template>

<style lang="scss" scoped>
.forgot {
  text-align: left;
  margin-bottom: 30px;

  a {
    color: #777777;
    font-size: 14px;
    text-decoration: underline;
  }

  p {
    overflow-wrap: normal;
  }
}
</style>

<script>
import { mapState } from "vuex";
import ValidationMixin from "@/mixins/validation-mixin";
import LoginLayout from "@/components/authentication/LoginLayout.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("UserRepository");

export default {
  components: { LoginLayout },
  mixins: [ValidationMixin],

  data() {
    return {
      password: null,
      password_confirmation: null,
      submitted: false
    };
  },

  computed: {
    ...mapState("Alert", { alertType: "type", alertMessage: "message" })
  },

  methods: {
    onSubmit() {
      this.$refs.resetPasswordForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.changePassword();
      });
    },

    changePassword: function () {
      UserRepository.passwordChange(this.password, this.$route.params.token)
        .then(() => {
          this.submitted = true;
          this.$store.dispatch("Alert/success", this.$t("user.password_reset_success"));
          this.$router.push("/");
        })
        .catch(() => {
          this.submitted = false;
          this.$store.dispatch("Alert/error", this.$t("general_error_title"));
        });
    }
  }
};
</script>
